var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "themelogout",
      style: { background: _vm.$store.getters.getColor("bgColorSecundary") },
    },
    [
      _c(
        "v-container",
        { staticClass: "pa-8" },
        [
          _c(
            "v-row",
            { staticClass: "mb-10", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex flex-column justify-center align-center",
                  attrs: { cols: "12" },
                },
                [
                  _vm.$store.getters.getLogoClaro
                    ? _c("img", {
                        staticClass: "mt-10 mb-8",
                        attrs: {
                          src: _vm.$store.getters.getLogoClaro,
                          width: "110",
                        },
                      })
                    : _c("h1", { staticClass: "white--text" }, [
                        _vm._v(_vm._s(_vm.$store.getters.getAppName)),
                      ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { "no-gutters": "", justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "8" } },
                [
                  _c(
                    "h1",
                    { staticClass: "white--text mb-5 title font-weight-bold" },
                    [_vm._v(" Informe seu CPF ")]
                  ),
                  _c(
                    "validation-observer",
                    { ref: "form" },
                    [
                      _c(
                        "v-form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.submit()
                            },
                          },
                        },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("base-text-field", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: "###.###.###-##",
                                        expression: "'###.###.###-##'",
                                      },
                                    ],
                                    attrs: {
                                      autoBlur: 14,
                                      rules: "required|cpf",
                                      inputmode: "numeric",
                                      type: "tel",
                                      id: "cpf",
                                      name: "CPF",
                                      label: "Digite aqui...",
                                      clearable: "",
                                      outlined: "",
                                      maxlength: "14",
                                      darkTheme: true,
                                    },
                                    model: {
                                      value: _vm.form.cpf,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "cpf", $$v)
                                      },
                                      expression: "form.cpf",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "text-button mb-1 mt-4 font-weight-bold",
                              attrs: {
                                block: "",
                                large: "",
                                type: "submit",
                                elevation: "10",
                                color: "white",
                                loading: _vm.loading,
                              },
                            },
                            [
                              _c("b", { staticClass: "primary--text" }, [
                                _vm._v("Continuar"),
                              ]),
                              _c(
                                "span",
                                { attrs: { slot: "loader" }, slot: "loader" },
                                [
                                  _c("v-progress-circular", {
                                    attrs: {
                                      indeterminate: "",
                                      width: 3,
                                      color: "primary",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-center" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "text-none font-weight-bold",
                              attrs: {
                                text: "",
                                large: "",
                                to: { name: "login" },
                                color: "primary",
                              },
                            },
                            [_vm._v("Já sou cadastrado")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-spacer", { staticStyle: { width: "100%" } }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "290" },
          model: {
            value: _vm.usuarioExiste,
            callback: function ($$v) {
              _vm.usuarioExiste = $$v
            },
            expression: "usuarioExiste",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("Usuário ativo"),
              ]),
              _c("v-card-text", [
                _vm._v("CPF informado já possui cadastro ativo!"),
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { block: "", text: "", color: "primary darken-2" },
                      on: {
                        click: function ($event) {
                          _vm.usuarioExiste = false
                        },
                      },
                    },
                    [_vm._v("Entendi")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }